import { TIMEOUT } from "dns"

let useFetchInited = {
    server:false,
    client:false
} as any
let useFetchProxy = async (url:string, configs:any) => {}
let useAsyncDataProxy = async (url:string, configs:any):Promise<any> => new Promise(()=>{})

const cache = {} as {[key:string]:{time:number, data:any}}


const tFetch = (url:string, _configs?:any) => {
    return new Promise<{status:200, payload:any} | {status:500, message:string}>(async (resolve, reject)=>{
        
        const configs = _configs || {} 

        // register useFetchProxy and useAsyncDataProxy
        if((process.server && !useFetchInited.server) && configs.useFetch){
            useFetchProxy = configs.useFetch
            useFetchInited.server = true
        }
        if((process.client && !useFetchInited.client) && configs.useAsyncData){
            useAsyncDataProxy = configs.useAsyncData
            
            useFetchInited.client = true
        }
    
        if(true || process.client){
            
            const DATA_TIMEOUT = 1000 * 60 * 10 // 10 min

            const requestHash = url + JSON.stringify(configs)

            try{
                cache[requestHash] = {
                    time: new Date().valueOf(),
                    data: "pending"
                }
                const jwtCookie = document.cookie.split(';').find((item:string) => item.includes('jwt'))
                const jwtCookieValue = jwtCookie ? jwtCookie.split('=')[1] : ''
                const fetchConfigs = 
                {
                    method:configs.method || "GET", 
                    params: {jwt:jwtCookieValue},
                } as any
                if(configs.body){
                    fetchConfigs.body = configs.body
                }
                const startTime = new Date().getTime()
                const res = await $fetch(
                    url,
                    fetchConfigs
                ) as any
                if(res.status == 200){
                    cache[requestHash] = {
                        time: new Date().valueOf(),
                        data: res
                    }
                    resolve(res)
                    return
                }else{
                    delete cache[requestHash]
                    resolve({
                        status: 500,
                        message: res.message
                    })
                }
            }catch(e){
                console.log('tFetch client error', e)
                resolve({
                    status: 500,
                    message: `${e}`
                })
            }
    
        }else{
            resolve({
                status: 400,
                message:"useFetchProxy not inited"
            })
        }
    })

}

export default tFetch